@import "../mixins/breakpoints";


::-webkit-scrollbar {
    width: 8px;
    height: 8px; }

::-webkit-scrollbar-track {
    background: #3b3b3b;
    border-radius: 50px; }

::-webkit-scrollbar-thumb {
    background: #a9a9a9;
    border-radius: 50px; }

::-webkit-scrollbar-thumb:horizontal:hover,
::-webkit-scrollbar-thumb:vertical:hover {
    background: #fff; }

::-webkit-scrollbar-track:hover {
    background: #545454; }

::-webkit-scrollbar-button {
    width: 8px;
    height: 13px;
    display: block; }

::-webkit-scrollbar-corner {
    background-color: transparent; }


html, body {
    overflow: hidden !important;

    div.site-content.launcher-scrollbar {
        height: 100vh !important;
        padding: 0 5px 0 0 !important;
        margin: 0 12px 0 0;
        overflow-y: auto !important;
        overflow-x: hidden !important; } }
